import React from 'react'


export const Banner = () => {
  return (
    <>
      <div className="banner">
        <img
          className="d-block"
          src={'/banner-img.JPG'}
          alt="banner"
        />
        <div className="banner__caption">
          <p>Zubná ambulancia</p>
          <p>OCEAN DENT</p>
          <a href="tel:+421910399793"><p style={{color: '#2f4a75'}}>+421 910 399 793</p></a>
        </div>
      </div>
    </>
  )
}