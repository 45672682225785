import React from 'react'
import { Banner } from '../components/banner'
import { MainContent } from '../components/mainContent'

const Domov = () => (
  <div>
    <Banner/>
    <MainContent/>
  </div>
)

export default Domov