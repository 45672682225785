import React from "react"
import {
  FaPhone,
  FaTooth,
  FaInfoCircle,
  FaEnvelope,
  FaMapMarker,
} from "react-icons/fa"
import { Link } from "gatsby"
import "animate.css/animate.min.css"
import ScrollAnimation from "react-animate-on-scroll"

export const MainContent = () => {
  return (
    <>
      {/* <div className="main-content__covid-info">
        <div className="d-flex py-3 justify-content-center">
          <div><FaInfoCircle/></div>
          <h3 className="mx-1 my-0">Oznam</h3>
        </div>
        <p>Počas nasledujúcich mesiacov budeme ordinovať v čase </p>
        <p><b>11:30 – 15:30</b>,</p>
        <p>avšak po telefonickej, resp. emailovej komunikácii.</p>
      </div> */}
      {/* <div className="main-content__covid-info">
        <div className="d-flex py-3 justify-content-center">
          <div>
            <FaInfoCircle />
          </div>
          <h3 className="mx-1 my-0">Oznam</h3>
        </div>
        <p>
          <b>Dovolenka 29.07. - 06.08.2024</b>
        </p>
        <p>
          <b>Zástup: MDDr. Boris Verba</b>
        </p>
        <p>
          Miesto prevádzkovania ambulancie: Poliklinika Vstupný areál U. S.
          Steel KE-ŠACA, 2. Poschodie Zubná ambulancia III.
        </p>
        <p>Kontakt: 055/7234 758</p>
      </div> */}
      <div className="d-md-flex flex-column main-content__services py-5">
        <div className="d-flex header justify-content-center pt-4">
          <div style={{ transform: "rotate(15deg)" }}>
            <FaTooth />
          </div>
          <h2>Čo ponúkame našim pacientom</h2>
        </div>
        <ScrollAnimation animateIn="fadeIn">
          <div className="row pt-4">
            <span className="col-md-6 col-lg-3 py-3">
              <span style={{ color: "#5dc2de" }}>✓</span> Prevencia
            </span>
            <span className="col-md-6 col-lg-3 py-3">
              <span style={{ color: "#5dc2de" }}>✓</span> Zubné ošetrenie
            </span>
            <span className="col-md-6 col-lg-3 py-3">
              <span style={{ color: "#5dc2de" }}>✓</span> Snímateľné náhrady
            </span>
            <span className="col-md-6 col-lg-3 py-3">
              <span style={{ color: "#5dc2de" }}>✓</span> Celokeramické fazety
            </span>
          </div>
          <div className="row">
            <span className="col-md-6 col-lg-3 py-3">
              <span style={{ color: "#5dc2de" }}>✓</span> Fixné náhrady
            </span>
            <span className="col-md-6 col-lg-3 py-3">
              <span style={{ color: "#5dc2de" }}>✓</span> Extrakcie zubov
            </span>
            <span className="col-md-6 col-lg-3 py-3">
              <span style={{ color: "#5dc2de" }}>✓</span> Dentálna hygiena
            </span>
            <span className="col-md-6 col-lg-3 py-3">
              <span style={{ color: "#5dc2de" }}>✓</span> Bielenie zubov
            </span>
          </div>
          <div className="row pb-4">
            <span className="col-md-6 col-lg-3 py-3">
              <span style={{ color: "#5dc2de" }}>✓</span> Konzultácie
            </span>
            <span className="col-md-6 col-lg-3 py-3">
              <span style={{ color: "#5dc2de" }}>✓</span> Intraorálny RTG
            </span>
            <span className="col-md-6 col-lg-3 py-3">
              <span style={{ color: "#5dc2de" }}>✓</span> Endodoncia
            </span>
            <span className="col-md-6 col-lg-3 py-3">
              <span style={{ color: "#5dc2de" }}>✓</span> Výplne zubov
            </span>
          </div>
        </ScrollAnimation>
      </div>
      <div className="main-content__about py-4">
        <div className="d-flex header justify-content-center pt-4">
          <div style={{ transform: "rotate(15deg)" }}>
            <FaTooth />
          </div>
          <h2>Zubná ambulancia</h2>
        </div>
        <p className="pt-4">
          Rozumieme, že väčšina pacientov zápasí so strachom navštíviť zubného
          lekára, ale náš tím urobí všetko, čo je v jeho silách, aby sa každý
          počas svojej návštevy u nás cítil príjemne a bezbolestne. Či už teda
          prídete na preventívne, štandardné alebo kozmetické stomatologické
          ošetrenie, vždy sa môžete spoľahnúť na pohodové stretnutie.
        </p>
        <Link
          to="/onas"
          activeStyle={{ color: "#4b8ebd" }}
          className="button-info py-2"
        >
          Viac o nás
        </Link>
      </div>
      <div
        className="main-content__offer row mx-auto my-3"
        style={{ width: "88%" }}
      >
        <div className="col-md-8 info"></div>
        <div className="col-md-4">
          <div className="rows pt-4">
            <ScrollAnimation animateIn="fadeIn">
              <div className="row info-list">
                <h5>Moderné technológie</h5>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="row info-list">
                <h5>Odborné znalosti</h5>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div className="row info-list">
                <h5>Najvyššia kvalita</h5>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
              <div
                className="row info-list"
                style={{
                  border: "1px solid #5dc2de",
                  color: "#5dc2de",
                  background: "white",
                  height: "95px",
                }}
              >
                <h5>
                  Ošetrujúci lekár
                  <br />
                  MDDr. Simona Adamec Vaššová
                </h5>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className="main-content__contact-info py-4 my-4">
        <h3>Prijímame nových pacientov</h3>
        <div className="contact-info__wrapper">
          <h5 className="py-3 m-0">
            Príďte na vstupné vyšetrenie a staňte sa spokojným klientom našej
            stomatologickej ordinácie.
          </h5>
          {/* <div className="rounded-circle mt-2 d-flex flex-column justify-content-center"> */}
          <ScrollAnimation animateIn="fadeIn">
            <div className="d-md-flex justify-content-between main-content__contact-info--icons m-auto">
              <div>
                <div className="rounded-icon rounded-circle">
                  <FaMapMarker size={30} />
                </div>
                <span>Rastislavova 786/45 Košice</span>
              </div>
              <div>
                <div className="rounded-icon rounded-circle">
                  <FaPhone size={30} />
                </div>
                <a href="tel:+421910399793">+421 910 399 793</a>
              </div>
              <div>
                <div className="rounded-icon rounded-circle">
                  <FaEnvelope size={30} />
                </div>
                <a href="mailto:oceandentkosice@gmail.com">
                  oceandentkosice@gmail.com
                </a>
              </div>
            </div>
            {/* <h5 className="pb-3 my-0">Napíšte nám na</h5> <a href="mailto:oceandentkosice@gmail.com"><FaEnvelope className="mr-1"/>oceandentkosice@gmail.com</a>
            <h5 className="py-3 my-0">alebo nám zavolajte na</h5><a href="tel:+421910399793"><FaPhone className="mr-1"/>+421 910 399 793</a> */}
          </ScrollAnimation>
          {/* </div> */}
        </div>
      </div>
      <div className="main-content__insurance py-4">
        <div className="main-content__insurance--wrapper">
          <h3>Zdravotné poisťovne</h3>
          <p>
            Zubná ambulancia Ocean Dent má uzavretú zmluvu s týmito zdravotnými
            poisťovňami:
          </p>
          <img
            src={"insurance.png"}
            alt="poistovne"
            style={{ margin: "0 auto", display: "block" }}
          ></img>
        </div>
      </div>
    </>
  )
}
